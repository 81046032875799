import { Flex } from '@pancakeswap/uikit'
import { useActiveHandle } from 'hooks/useEagerConnect.bmp'
import { useWallet } from 'hooks/useWallet'
import styled from 'styled-components'

export const StyledUserMenu = styled(Flex)`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 36px;
  padding: 4px 8px;
  padding-left: 40px;
  margin-left: 0;
  position: relative;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 200px;
    height: 44px;
    padding-left: 46px;
  }

  img {
    width: auto;
    height: 86%;
    object-fit: contain;
    margin-right: 6px;
  }
`
export const LabelText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 600;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 16px;
    margin-left: 8px;
    margin-right: 4px;
  }
`

const ConnectWallet = ({ account, onPresentWalletModal, ...props }: any) => {
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWallet()

  const handleClick = () => {
    if (account) {
      onPresentWalletModal()
    } else if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  return (
    <StyledUserMenu onClick={handleClick} {...props}>
      <LabelText>{accountEllipsis || 'Connect Wallet'}</LabelText>
    </StyledUserMenu>
  )
}

export default ConnectWallet
