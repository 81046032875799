import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { FetchStatus } from 'config/constants/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useGetCakeBalance } from 'hooks/useTokenBalance'
import { ChainLogo } from 'components/Logo/ChainLogo'
import { useBalance } from 'wagmi'
import { formatBigNumber } from 'utils/formatBalance'
import CopyAddress from './CopyAddress'
import WalletRow from './WalletRow'

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId, chain } = useActiveWeb3React()
  const isBSC = chainId === ChainId.BSC
  // const bnbBalance = useBalance({ addressOrName: account, chainId: ChainId.BSC })
  const nativeBalance = useBalance({ addressOrName: account, enabled: !isBSC })
  const native = useNativeCurrency()
  // const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useGetCakeBalance()
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }

  return (
    <>
      <Box mb="24px">
        <Text fontSize="16px" fontWeight="600" mb="8px">
          {t('Your Address')}
        </Text>
        <CopyAddress account={account} />
      </Box>
      <Box mb="8px">
        <Flex alignItems="center" mb="8px">
          <ChainLogo chainId={chainId} />
          <Text fontSize="16px" fontWeight="600" ml="8px">
            {chain?.name}
          </Text>
        </Flex>
        <WalletRow
          leftNode={`${native?.symbol} Balance`}
          rightNode={nativeBalance?.data ? formatBigNumber(nativeBalance.data.value, 6) : '-'}
          mb="24px"
        />
      </Box>

      <Button width="100%" maxWidth="200px" margin="0 auto" onClick={handleLogout}>
        {t('Disconnect')}
      </Button>
    </>
  )
}

export default WalletInfo
