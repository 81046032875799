import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  /* Poppins */
  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins/Poppins-Regular.ttf');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: bold;
    src: url('/fonts/Poppins/Poppins-Bold.ttf');
  }

  /* SVN-Gilroy */
  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('/fonts/SVN-Gilroy/SVN-Gilroy Regular.otf');
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    font-weight: bold;
    src: url('/fonts/SVN-Gilroy/SVN-Gilroy Bold.otf');
  }
  * {
    font-family: Poppins;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .ant-select-dropdown {
    &.token-select {
      background-color: #111b1e;

      .ant-select-item-option-selected {
      background-color: #3485ff;

      }
    }
  }

  .ant-pagination-options {
    display: inline-block !important;
  }
`

export default GlobalStyle
